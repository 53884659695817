<template>
<NewClient />
</template>

<script>
import NewClient from '../components/NewClientPage'

export default {
    components: {
        NewClient
    }
}
</script>
